<template>
	<w-btn icon="publish" @click="$emit('input', true)">
		{{ $t('projects.generate_engagement_letter') }}
	</w-btn>
</template>
<script>
export default {
	name: 'EngagementLetterGenerationButton',
	props: {
		value: {
			type: Boolean,
			required: true
		}
	}
}
</script>
